import { Action } from 'store/modules/actions'
import { UserSkill } from 'store/modules/user_skills'

export const ACTION_COMPLETED_MODAL_ID = 'action-completed-modal'

export type OpenFormModalProps = {
  action: Action
  source: string
  userSkills: UserSkill[]
}
